require("../css/plugin.scss");
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css";
import autoComplete from "@tarekraafat/autocomplete.js";

let autocompleteFields = document.querySelectorAll('.oct_offres_emploi_plugin_field_autocomplete');

if (autocompleteFields !== null) {
    let url = window.location.origin + '/wp-admin/admin-ajax.php',
        autocompleteFieldsToRemove = document.querySelectorAll('.oct_offres_emploi_plugin_field_autocomplete--remove');

    autocompleteFields.forEach(function (field) {
        field.addEventListener('keypress', function (e) {
            return e.which !== 13;
        });

        let autocompleteConfig = {
            selector: '#' + field.id,
            data: {
                src: async function (query) {
                    let req = url + '?action=oct_post_search&term=' + query,
                        fetchInit = {
                            method: 'GET'
                        };

                    return await fetch(req, fetchInit)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (data) {
                            return data;
                        });
                },
                keys: ['label'],
            },
            resultsList: {
                id: 'oct_result_list',
                destination: '#' + field.id,
            },
            events: {
                input: {
                    selection: function (event) {
                        let result = event.detail.selection.value;

                        field.value = result.label;
                        field.parentNode.parentNode.querySelector('.oct_offres_emploi_plugin_field_autocomplete--id').value = result.id;
                    }
                }

            }
        };

        let autoCompleteJS = new autoComplete(autocompleteConfig);
    });

    autocompleteFieldsToRemove.forEach(function (link) {
        link.addEventListener('click', function () {
            //alert('Etes vous sûr de vouloir supprimer ce lien ?');
            this.parentNode.parentNode.querySelector('.oct_offres_emploi_plugin_field_autocomplete--id').value = '';
            this.parentNode.parentNode.querySelector('.oct_offres_emploi_plugin_field_autocomplete').value = '';
            document.querySelector('#oct_offres_emploi_configuration_form').submit();
        });
    });
}